// Generated by Framer (102b37e)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["okC5n9H2_"];

const variantClassNames = {okC5n9H2_: "framer-v-1c40t9f"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; background?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "okC5n9H2_", background: e8BK3I59S = "rgb(227, 227, 227)", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "okC5n9H2_", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-wcs7e", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1c40t9f", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"okC5n9H2_"} ref={ref} style={{backgroundColor: e8BK3I59S, borderBottomLeftRadius: 32, borderBottomRightRadius: 32, borderTopLeftRadius: 32, borderTopRightRadius: 32, ...style}} transition={transition}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-wcs7e [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-wcs7e * { box-sizing: border-box; }", ".framer-wcs7e .framer-18zleuh { display: block; }", ".framer-wcs7e .framer-1c40t9f { height: 400px; overflow: hidden; position: relative; width: 400px; will-change: transform; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 400
 * @framerIntrinsicWidth 400
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"e8BK3I59S":"background"}
 */
const FramerDeEOktvNw: React.ComponentType<Props> = withCSS(Component, css, "framer-wcs7e") as typeof Component;
export default FramerDeEOktvNw;

FramerDeEOktvNw.displayName = "Image";

FramerDeEOktvNw.defaultProps = {height: 400, width: 400};

addPropertyControls(FramerDeEOktvNw, {e8BK3I59S: {defaultValue: "rgb(227, 227, 227)", title: "Background", type: ControlType.Color}} as any)

addFonts(FramerDeEOktvNw, [])